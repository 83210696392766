import { useState } from "react";
import { useDispatch } from "react-redux";
import  {quantityDec} from "../redux/action";
import   {quantityInc}  from "../redux/action";

export default function Counter(props) {
    console.log(props);
    const dispatch = useDispatch();
    let [count, setCount] = useState(1);
    let [disableDec, setDisableDec] = useState(true);
    let [disableInc, setDisableInc] = useState(false);
    let qDec = ()=>{
        if(count>1){
            setCount(count-1);
        }
        if(count===2){
            setDisableDec(true);
        }
        if(count<=10){
            setDisableInc(false);
        }
    }
    let qInc = ()=>{
        if(count<10){
            setCount(count+1);
        }
        if(count===9){
            setDisableInc(true);
        }
        if(count>=1){
            setDisableDec(false);
        }
    }
    function counterInpHandler(e){
        const newVal = e.target.value;
        if(!isNaN(newVal) && (newVal === "" || /^(10|[1-9])$/.test(newVal))){
            setCount(newVal === "" ? "" : parseInt(newVal));
            if(parseInt(newVal) === 1){
                setDisableDec(true);
                setDisableInc(false);
            }else if(parseInt(newVal) === 10){
                setDisableInc(true);
                setDisableDec(false);
            }else{
                setDisableDec(false);
                setDisableInc(false);

            }
        }
    }
    return (
        <>
            <div className="cart-actions">
                <span>
                    <button onClick={()=>{qDec(); dispatch(quantityDec({"count":count, "price":props.proDetail.price}));}} disabled={disableDec} className={disableDec?"quantity-dec disable":"quantity-dec"}>-</button>
                    <input type="text" value={count} onChange={counterInpHandler} className="quantity-show" />
                    <button onClick={()=>{qInc(); dispatch(quantityInc({"count":count+1, "price":props.proDetail.price}));}} disabled={disableInc} className={disableInc?"quantity-inc disable":"quantity-inc"}>+</button>
                </span>
            </div>
        </>
    )
}

// 100 *4 = 400
// 400 / 4 = 100