import { Link } from "react-router-dom";
import img from "../assets/img";
function Login() {
    return (
        <section className="section-gap bg-light">
            <div className="site-width main-con">
                <div className="box">

                    <div className="logo logo-con">
                        <Link to="/">
                            <img src={img.logo} alt="logo" />
                            <h6>Male<br /><span>Made</span></h6>
                        </Link>
                    </div>


                    <form action="#" method="post">
                        <h3>Sign in</h3>
                        <div className="group">
                            <label for="cpf_no">Email or phone number</label>
                            <input type="text" className="form-control" name="cpf" />
                        </div>

                        <div className="group">
                            <label for="mob_no">Password</label>
                            <input type="text" className="form-control" name="mobile" />
                        </div>

                        <div className="group group-btn">
                            <input type="submit" value="Continue" className="sub-btn btn" />
                        </div>

                        <div className="group notice">
                            <span>
                                By continuing, you agree to MaleMade's
                                <Link to="/"> Conditions of Use </Link>and
                                <Link to="/"> Privacy Notice.</Link>
                            </span>
                        </div>
                        <div className="signup-message">
                            <span>New to MaleMade? </span>
                            <Link to="/signup">
                                Create an account here
                            </Link>
                        </div>
                    </form>

                </div>

            </div>
        </section>
    )
}
export default Login;