import {useDispatch} from "react-redux";
import { addToCart } from "../redux/action";

function DisplayProducts(props) {
    const dispatch = useDispatch();
    return (
        <section className="section-gap bg-light">
            <div className="site-width">
                <h5 className="section-heading txt-center">Premium {props.cat[0].cat} Collections</h5>
                <div className="product-section">
                    {
                        props.cat.map((items, ind) => {
                            return (
                                <div className="card" key={ind}>
                                    <div className="card-img">
                                        <img src={items.imgPath} alt="shoes" />
                                    </div>
                                    <p className="pro-name">
                                        {items.name}
                                    </p>
                                    <p className="pro-price">
                                        <span>₹</span>{items.price}
                                    </p>
                                    <div className="icons-con">
                                        <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-heart" viewBox="0 0 16 16">
                                                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                            </svg>
                                        </button>

                                        {/* add to cart */}
                                        <button onClick={()=>dispatch(addToCart(items))}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" /></svg>
                                        </button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default DisplayProducts;