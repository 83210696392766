import img from '../assets/img';
import { Link } from 'react-router-dom';
import dbProducts from '../products';
import Banner from './slider';

function Homepage() {
    return (
        <>
            <Banner />
            <ShopByCategories show={[6, 12, 18, 32, 40, 46]} proList={dbProducts} />
        </>
    )
}
<Banner/>

export function ShopByCategories(props) {
    return (
        <section className="section-gap">
            <div className="site-width">
                <h4 className="section-heading">Shop By Category</h4>
                <div className="card-con">
                    {
                        props.proList.filter(x => props.show.includes(x.id)).map((items, key) => 
                            <Link to={"/"+items.cat} className="card" key={key}>
                                <img src={items.imgPath} alt="shirt" />
                                <p className="pro-title">{items.cat}</p>
                            </Link>
                        )
                       
                    }
                </div>
            </div>
        </section>
    )
}
// 6, 13, 18, 32, 40, 46
// export function ShopByCategories() {
//     return (
//         <section className="section-gap">
//             <div className="site-width">
//                 <h4 className="section-heading">Shop By Category</h4>
//                 <div className="card-con">
//                     <Link to="/" className="card">
//                         <img src={shirts.pink} alt="shirt" />
//                         <p className="pro-title">Casual Shirt</p>
//                     </Link>
//                     <Link to="/" className="card">
//                         <img src={jackets.brown} alt="jacket" />
//                         <p className="pro-title">Jackets</p>
//                     </Link>
//                     <Link to="/" className="card">
//                         <img src={jeans.denim} alt="jeans" />
//                         <p className="pro-title">Jeans</p>
//                     </Link>
//                     <Link to="/" className="card">
//                         <img src={sweaters.multiColor} alt="sweater" />
//                         <p className="pro-title">Sweaters</p>
//                     </Link>
//                     <Link to="/" className="card">
//                         <img src={shorts.olive} alt="shorts" />
//                         <p className="pro-title">Shorts</p>
//                     </Link>
//                     <Link to="/" className="card">
//                         <img src={shoes.addidas} alt="shoes" />
//                         <p className="pro-title">Shoes</p>
//                     </Link>
//                 </div>
//             </div>
//         </section>
//     )
// }

export default Homepage;