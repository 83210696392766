import { Link, Outlet } from 'react-router-dom';
import img from '../assets/img';
import { useEffect, useState } from 'react';

function Navbar(props) {
    let [isActive, setActive] = useState(false);
    useEffect(()=>{
        if(isActive){
            document.querySelector(".bottom-nav").style.right = 0;
        }else{
            document.querySelector(".bottom-nav").style.right = "-19rem";
        }
    },[isActive])
    return (
        <>
            <header>
                <nav className="site-width">
                    <div className="top-nav">
                        <div className="logo-con">
                            <Link to="/">
                                <img src={img.logo} alt="male made logo" />
                                <h6>Male<br /><span>Made</span></h6>
                            </Link>
                        </div>
                        <div className="search-con">
                            <div className="search-bar">
                                <form>
                                    <input type="text" placeholder="Search for products" />
                                    <button type="submit">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            className="bi bi-search" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                        </svg>
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="login-cart-con">
                            <span>
                                <Link to="/login" className="btn login-btn">
                                    Sign in
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        className="bi bi-person-fill" viewBox="0 0 16 16">
                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                    </svg>
                                </Link>
                                <div className="signup-tooltip">
                                    <div>
                                    New Customer? <Link to="signup">Sign up</Link>
                                    </div>
                                </div>
                            </span>
                            <span>
                                {/* cart/bag */}
                                <Link to="/cart" className="cart-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                                        className="bi bi-bag" viewBox="0 0 16 16">
                                        <path
                                            d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                                    </svg>
                                    <span className="cart-badge">{props.upstate.iniItems}</span>
                                </Link>
                            </span>
                            <div className={isActive?"mob-menu active":"mob-menu"} onClick={()=>setActive(!isActive)}>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-nav">
                        <ul>
                            <li>
                                <Link to="/shirts">SHIRTS</Link>
                            </li>
                            <li>
                                <Link to="/jeans">JEANS</Link>
                            </li>
                            <li>
                                <Link to="/shorts">SHORTS</Link>
                            </li>
                            <li>
                                <Link to="/jackets">JACKETS</Link>
                            </li>
                            <li>
                                <Link to="/sweaters">SWEATERS</Link>
                            </li>
                            <li>
                                <Link to="/shoes">SHOES</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <main>
                <Outlet />
            </main>
        </>
    )
}

export default Navbar;