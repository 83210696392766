export const addToCart = (item)=>{
    return {type:"Increment", value:item}
}
export const removeFromCart = (item)=>{
    return {
        type:"Decrement", value:item
    }
}
export const quantityInc = (item)=>{
    return {
        type:"quantityInc", value:item
    }
}
export const quantityDec = (item)=>{
    return {
        type:"quantityDec", value:item
    }
}