import logo from './malemade-logo.png'
import favicon from './malemade-favicon.png'
import secureSvg from './100-secure.svg';
import allPaymentTypeSvg from './bottom-logo.svg';
import banJacketMob from './banner-jacket-mob.jpg';
import banJacket from './banner-jacket.jpg';
import banJeansMob from './banner-jeans-mob.jpg';
import banJeans from './banner-jeans.jpg';
import banShirtMob from './banner-shirt-mob.jpg';
import banShirt from './banner-shirt.jpg';
import banShoesMob from './banner-shoes-mob.jpg';
import banShoes from './banner-shoes.jpg';
import banSweaterMob from './banner-sweater-mob.jpg';
import banSweater from './banner-sweater.jpg';

const staticImg = {
    logo : logo,
    favicon : favicon,
    secureSvg : secureSvg,
    allPaymentTypeSvg : allPaymentTypeSvg,
    banJacketMob : banJacketMob,
    banJacket : banJacket,
    banJeansMob : banJeansMob,
    banJeans : banJeans,
    banShirtMob : banShirtMob,
    banShirt : banShirt,
    banShoesMob : banShoesMob,
    banShoes : banShoes,
    banSweaterMob : banSweaterMob,
    banSweater : banSweater
}

export default staticImg;