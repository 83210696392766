const iniState = {
    iniItems: 0,
    iniPrice: 0,
    selectedProducts: []
}
// in selectedProducts array we will add products that are added to cart by user
const update = (state = iniState, action) => {
    if (action.type === "Increment") {
        if (!state.selectedProducts.includes(action.value)) {
            return {
                ...state, iniItems: state.iniItems + 1, iniPrice: state.iniPrice + action.value.price, selectedProducts: [...state.selectedProducts, action.value]
            }
        }
        else {
            return {
                ...state
            }
        }
    } else if (action.type === "Decrement") {
        return {
            ...state, iniItems: state.iniItems - 1, iniPrice: state.iniPrice - action.value.price, selectedProducts: state.selectedProducts.filter(item => item !== action.value)
        }
    } else if (action.type === "quantityInc") {
        return {
            ...state, iniPrice: state.iniPrice + action.value.price
        }
    } else if (action.type === "quantityDec") {
        return {
            ...state, iniPrice: state.iniPrice - action.value.price
        }
    } else {
        return state;
    }
}

export default update;