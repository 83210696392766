import Counter from "./counter";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart } from "../redux/action";

function MyCart() {
    const x = useSelector(val => val.update);
    let dispatch = useDispatch();
    return (
        <>
            <section className="section-gap">
                <div className="site-width">
                    <div className="mycart">
                        <div className="col">
                            <div className="cart-head">
                                <h5>Shopping Cart</h5>
                            </div>

                            {
                                x.selectedProducts.map((items, ind) => {
                                    return (
                                        <div className="cart-list" key={ind}>
                                            <div className="cart-img">
                                                <img src={items.imgPath} alt="shoes" />
                                            </div>
                                            <div className="cart-details">
                                                <div>
                                                    <p className="pro-name">
                                                        {items.name}
                                                    </p>
                                                    <p className="mob-price">
                                                        {/* this price will be shown on mobile devices */}
                                                        <span>₹</span>{items.price}
                                                    </p>
                                                    <p>
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse minima voluptatem officiis deserunt expedita assumenda.
                                                    </p>
                                                    <div className="cart-actions">
                                                        <Counter proDetail={items}/>
                                                        <button onClick={() => dispatch(removeFromCart(items))} className="del">Remove</button>
                                                    </div>
                                                </div>
                                                <p className="cart-price pro-price">
                                                    {/* this price will be shown on desktop devices */}
                                                    <span>₹</span>{items.price}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className="col total-col">
                            <div className="cart-head">
                                <h5>Subtotal</h5>
                            </div>
                            <div className="cart-list cart-total">
                                <div className="price-details">
                                    <span>Price ({x.iniItems} items)</span>
                                    <span>
                                        <span>₹</span>{x.iniPrice}
                                    </span>
                                </div>
                                <div className="price-details">
                                    <span>Discount 10% </span>
                                    <span className="discount">
                                        {(x.iniPrice*10)/100}
                                    </span>
                                </div>
                                <div className="price-details">
                                    <span>Delivery Charges </span>
                                    <span className="discount">
                                        Free
                                    </span>
                                </div>
                                <div className="final-total">
                                    <span>Total Amount </span>
                                    <span className="total-amt">
                                        <span>₹</span>{Math.round(x.iniPrice - ((x.iniPrice*10)/100))}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MyCart;