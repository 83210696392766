import { Routes, Route, BrowserRouter } from "react-router-dom";
import Navbar from "./components/navbar";
import Homepage from "./components/home";
import Footer from "./components/footer";
import DisplayProducts from "./components/product-display";
import proList from "./products";
import ContactUs from "./components/contact";
import Login from "./components/login";
import Signup from "./components/signup";
import MyCart from "./components/cart";
import { useSelector } from "react-redux";


function App() {
  const myState = useSelector(state=>state.update);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navbar upstate={myState}/>}>
            <Route index element={<Homepage />}></Route>
            <Route path="shirts" element={<DisplayProducts cat={proList.filter(i=>i.cat==="shirts")}/>}></Route>
            <Route path="jackets" element={<DisplayProducts cat={proList.filter(i=>i.cat==="jackets")}/>}></Route>
            <Route path="jeans" element={<DisplayProducts cat={proList.filter(i=>i.cat==="jeans")}/>}></Route>
            <Route path="sweaters" element={<DisplayProducts cat={proList.filter(i=>i.cat==="sweaters")}/>}></Route>
            <Route path="shorts" element={<DisplayProducts cat={proList.filter(i=>i.cat==="shorts")}/>}></Route>
            <Route path="shoes" element={<DisplayProducts cat={proList.filter(i=>i.cat==="shoes")}/>}></Route>
            <Route path="login" element={<Login/>}></Route>
            <Route path="signup" element={<Signup/>}></Route>
            <Route path="cart" element={<MyCart/>}></Route>
            <Route path="contact-us" element={<ContactUs/>}></Route>
          </Route>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
