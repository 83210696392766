// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import { Link } from 'react-router-dom';


export function Banner() {
    return (
        <Swiper
            pagination={{
                type: 'fraction',
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
            Autoplay={true}
        >
            <SwiperSlide className='slide-1'>
                <Link to="/shirts" className="slides">
                    <div className="slide-text">
                        <p className="sub-title">Up to 20% off</p>
                        <h5 className="title">Upgrade your wardrobe with Roadster shirts! Unbeatable sale happening now. Grab
                            yours before they're gone!</h5>
                    </div>
                    <div></div>
                </Link>
            </SwiperSlide>
            <SwiperSlide className='slide-2'>
                <Link to="/jackets" className="slides">
                    <div className="slide-text">
                        <p className="sub-title">Up to 10% off</p>
                        <h5 className="title">Puma Jackets! Unbeatable Flash sale happening now.</h5>
                    </div>
                    <div></div>
                </Link>
            </SwiperSlide>
            <SwiperSlide className='slide-3'>
                <Link to="/jeans" className="slides">
                    <div className="slide-text">
                        <p className="sub-title">Up to 33% off</p>
                        <h5 className="title">Get the best deal of the month with Levis Jeans! Hurry up!</h5>
                    </div>
                    <div></div>
                </Link>
            </SwiperSlide>
            <SwiperSlide className='slide-4'>
                <Link to="/sweaters" className="slides">
                    <div className="slide-text">
                        <p className="sub-title">Up to 35% off</p>
                        <h5 className="title">Elevate your winter style with Jack and Jones sweaters. On sale now for a limited
                            time!</h5>
                    </div>
                    <div></div>
                </Link>
            </SwiperSlide>
            <SwiperSlide className='slide-5'>
                <Link to="/shoes" className="slides">
                    <div className="slide-text">
                        <p className="sub-title">Up to 20% off</p>
                        <h5 className="title">Step up your shoe game with Addidas. Limited-time sale happening now. Don't miss
                            out!</h5>
                    </div>
                    <div></div>
                </Link>
            </SwiperSlide>
        </Swiper>
    )
}

export default Banner;
