import shirtblack from "./assets/products/shirts/shirts-black.jpg";
import shirtsblue from "./assets/products/shirts/shirts-blue.jpg";
import check from "./assets/products/shirts/shirts-check.jpg";
import linepink from "./assets/products/shirts/shirts-linepink.jpg";
import shirtsolive from "./assets/products/shirts/shirts-olive.jpg";
import pink from "./assets/products/shirts/shirts-pink.jpg";
import white from "./assets/products/shirts/shirts-white.jpg";
import yellow from "./assets/products/shirts/shirts-yellow.jpg";
import baggy from "./assets/products/jeans/jeans-baggy.jpg";
import jeansblack from "./assets/products/jeans/jeans-black.jpg";
import jeansblue from "./assets/products/jeans/jeans-blue.jpg";
import charcol from "./assets/products/jeans/jeans-charcoal.jpg";
import jeansdenim from "./assets/products/jeans/jeans-denim.jpg";
import jeansblack2 from "./assets/products/jeans/jeans-black2.jpg";
import lowfade from "./assets/products/jeans/jeans-lowfade.jpg";
import tapered from "./assets/products/jeans/jeans-tapered.jpg";
import bomber from "./assets/products/jackets/jackets-bomber.jpg";
import brown from "./assets/products/jackets/jackets-brown.jpg";
import jacketdenim from "./assets/products/jackets/jackets-denim.jpg";
import jacketsolive from "./assets/products/jackets/jackets-olive.jpg";
import running from "./assets/products/jackets/jackets-running.jpg";
import sporty from "./assets/products/jackets/jackets-sporty.jpg";
import varsity from "./assets/products/jackets/jackets-varsity.jpg";
import windcheater from "./assets/products/jackets/jackets-windcheater.jpg";
import sweaterblue from "./assets/products/sweaters/sweaters-blue.jpg";
import colourful from "./assets/products/sweaters/sweaters-colourful.jpg";
import green from "./assets/products/sweaters/sweaters-green.jpg";
import halfcheck from "./assets/products/sweaters/sweaters-halfcheck.jpg";
import long from "./assets/products/sweaters/sweaters-long.jpg";
import cardigan from "./assets/products/sweaters/sweaters-cardigan.jpg";
import pullover from "./assets/products/sweaters/sweaters-pullover.jpg";
import knit from "./assets/products/sweaters/sweaters-knit.jpg";
import shortsblack from "./assets/products/shorts/shorts-black.jpg";
import shortsdenim from "./assets/products/shorts/shorts-denim.jpg";
import shortsblue from "./assets/products/shorts/shorts-blue.jpg";
import colorful from "./assets/products/shorts/shorts-colorful.jpg";
import shortsdenim2 from "./assets/products/shorts/shorts-denim2.jpg";
import khaki from "./assets/products/shorts/shorts-khaki.jpg";
import navy from "./assets/products/shorts/shorts-navy.jpg";
import shortsolive from "./assets/products/shorts/shorts-olive.jpg";
import asian from "./assets/products/shoes/shoes-asian.jpg";
import brownloader from "./assets/products/shoes/shoes-brownloafer.jpg";
import canvas from "./assets/products/shoes/shoes-canvas.jpg";
import levis from "./assets/products/shoes/shoes-levis.jpg";
import redtape from "./assets/products/shoes/shoes-redtape.jpg";
import sneakers from "./assets/products/shoes/shoes-sneakers.jpg";
import tarzan from "./assets/products/shoes/shoes-tarzan.jpg";
import textured from "./assets/products/shoes/shoes-textured.jpg";


const dbProducts = [ 
    // shirts
    {
        id: 1,
        name: "Mast & Harbour",
        des: "black Slim Fit Striped Casual Sustainable Shirt",
        price: 100,
        imgPath: shirtblack,
        cat: "shirts"
    },
    {
        id: 2,
        name: "Roadster",
        des: "Blue Slim Fit Striped Casual Sustainable Shirt",
        price: 100,
        imgPath: shirtsblue,
        cat: "shirts"
    },
    {
        id: 3,
        name: "Levis",
        des: "Check Slim Fit Casual Sustainable Shirt",
        price: 200,
        imgPath: check,
        cat: "shirts"
    },
    {
        id: 4,
        name: "H&M",
        des: "line Pink Slim Fit Striped Casual Sustainable Shirt",
        price: 599,
        imgPath: linepink,
        cat: "shirts"
    },
    {
        id: 5,
        name: "Highlander",
        des: "Olive Slim Fit Striped Casual Sustainable Shirt",
        price: 559,
        imgPath: shirtsolive,
        cat: "shirts"
    },
    {
        id: 6,
        name: "Roadster",
        des: "Pink Slim Fit Striped Casual Sustainable Shirt",
        price: 489,
        imgPath: pink,
        cat: "shirts"
    },
    {
        id: 7,
        name: "H&M",
        des: "White Slim Fit Striped Casual Sustainable Shirt",
        price: 849,
        imgPath: white,
        cat: "shirts"
    },
    {
        id: 8,
        name: "Lacoste",
        des: "Yellow Slim Fit Striped Casual Sustainable Shirt",
        price: 749,
        imgPath: yellow,
        cat: "shirts"
    },
    // jeans
    {
        id: 9,
        name: "Bene Kleed",
        des: "Relaxed Baggy Fit Cotton Jeans",
        price: 1199,
        imgPath: baggy,
        cat: "jeans"
    },
    {
        id: 10,
        name: "Highlander",
        des: "Mid Rise Black jeans",
        price: 999,
        imgPath: jeansblack,
        cat: "jeans"
    },
    {
        id: 11,
        name: "Flying Machine",
        des: "Tapered Fit Blue Jeans",
        price: 1099,
        imgPath: jeansblue,
        cat: "jeans"
    },
    {
        id: 12,
        name: "Levis",
        des: "Straight Cargo Jeans",
        price: 1299,
        imgPath: charcol,
        cat: "jeans"
    },
    {
        id: 13,
        name: "Roadster",
        des: "Faded Stretchable Denim Jeans",
        price: 999,
        imgPath: jeansdenim,
        cat: "jeans"
    },
    {
        id: 14,
        name: "H&M",
        des: "Mid-Rise Black Stretchable Jeans",
        price: 849,
        imgPath: jeansblack2,
        cat: "jeans"
    },
    {
        id: 15,
        name: "Lee",
        des: "Mid-Rise Low Fade Stretchable Jeans",
        price: 1149,
        imgPath: lowfade,
        cat: "jeans"
    },
    {
        id: 16,
        name: "Roadster",
        des: "Straight Fit Tapered Cotton Jeans",
        price: 849,
        imgPath: tapered,
        cat: "jeans"
    },
    // jackets
    {
        id: 17,
        name: "Campus Sutra",
        des: "Mock Collar Bomber Jacket",
        price: 1049,
        imgPath: bomber,
        cat: "jackets"
    },
    {
        id: 18,
        name: "Instafab Plus",
        des: "Suede Windcheater Bomber Jacket",
        price: 1499,
        imgPath: brown,
        cat: "jackets"
    },
    {
        id: 19,
        name: "Mast & Harbour",
        des: "Pure Cotton Denim Jacket",
        price: 1449,
        imgPath: jacketdenim,
        cat: "jackets"
    },
    {
        id: 20,
        name: "Voxati",
        des: "Solid Denim Jacket",
        price: 999,
        imgPath: jacketsolive,
        cat: "jackets"
    },
    {
        id: 21,
        name: "Roadster",
        des: "Training or Gym Sporty Jacket",
        price: 1799,
        imgPath: running,
        cat: "jackets"
    },
    {
        id: 22,
        name: "High Star",
        des: "Colourblocked puffer Jacket",
        price: 1199,
        imgPath: sporty,
        cat: "jackets"
    },
    {
        id: 23,
        name: "Bewakoof",
        des: "Windcheater Sporty Jacket",
        price: 1299,
        imgPath: varsity,
        cat: "jackets"
    },
    {
        id: 24,
        name: "Reebok",
        des: "Performance Training Jacket",
        price: 1679,
        imgPath: windcheater,
        cat: "jackets"
    },
    // sweaters
    {
        id: 25,
        name: "Mark & Spencer",
        des: "Stand Collar Pullover Sweater",
        price: 679,
        imgPath: sweaterblue,
        cat: "sweaters"
    },
    {
        id: 26,
        name: "Royal Enfield",
        des: "Blue Pullover Sweaters",
        price: 899,
        imgPath: colourful,
        cat: "sweaters"
    },
    {
        id: 27,
        name: "Roadster",
        des: "Pullover Sweaters",
        price: 524,
        imgPath: green,
        cat: "sweaters"
    },
    {
        id: 28,
        name: "Marks & Spancer",
        des: "Striped Pullover Sweater",
        price: 1749,
        imgPath: halfcheck,
        cat: "sweaters"
    },
    {
        id: 29,
        name: "Mast & Harbour",
        des: "Self Design Pullover Sweater",
        price: 538,
        imgPath: long,
        cat: "sweaters"
    },
    {
        id: 30,
        name: "Fugazee",
        des: "Printed Acrylic Cardigan",
        price: 1139,
        imgPath: cardigan,
        cat: "sweaters"
    },
    {
        id: 31,
        name: "Indian Terrain",
        des: "Mock Collar Pullover Sweater",
        price: 1349,
        imgPath: pullover,
        cat: "sweaters"
    },
    {
        id: 32,
        name: "Here & Now",
        des: "Cable Knit Pullover",
        price: 989,
        imgPath: knit,
        cat: "sweaters"
    },
    // shorts    
    {
        id: 33,
        name: "Dpassion",
        des: "Running Sports Shorts",
        price: 449,
        imgPath: shortsblack,
        cat: "shorts"
    },
    {
        id: 34,
        name: "HRX by Hrithik",
        des: "Rapid Dry Training Shorts",
        price: 635,
        imgPath: shortsdenim,
        cat: "shorts"
    },
    {
        id: 35,
        name: "Reslag",
        des: "Cotton Cargo Shorts",
        price: 659,
        imgPath: shortsblue,
        cat: "shorts"
    },
    {
        id: 36,
        name: "HRX by Hritik",
        des: "Cotton Cargo Shorts",
        price: 549,
        imgPath: colorful,
        cat: "shorts"
    },
    {
        id: 37,
        name: "H&M",
        des: "Regular Fit Sweatshorts",
        price: 799,
        imgPath: shortsdenim2,
        cat: "shorts"
    },
    {
        id: 38,
        name: "Indian Terrain",
        des: "Cable Knit Pullover",
        price: 797,
        imgPath: khaki,
        cat: "shorts"
    },
    {
        id: 39,
        name: "Van Heusen",
        des: "Pure Cotton Lounge Shorts",
        price: 663,
        imgPath: navy,
        cat: "shorts"
    },
    {
        id: 40,
        name: "Highlander",
        des: "Slim Fir Regular Shorts",
        price: 377,
        imgPath: shortsolive,
        cat: "shorts"
    },
    // shoes
    {
        id: 41,
        name: "U.S. Polo",
        des: "Clarkin Sneakers",
        price: 1799,
        imgPath: asian,
        cat: "shoes"
    },
    {
        id: 42,
        name: "Red Tape",
        des: "Colourblocked PU Sneakers",
        price: 1649,
        imgPath: brownloader,
        cat: "shoes"
    },
    {
        id: 43,
        name: "Roadster",
        des: "Textured PU Sneaker",
        price: 956,
        imgPath: canvas,
        cat: "shoes"
    },
    {
        id: 44,
        name: "Puma",
        des: "Lace-ups Sneakers",
        price: 1399,
        imgPath: levis,
        cat: "shoes"
    },
    {
        id: 45,
        name: "Addidas",
        des: "Perforations Sneakers",
        price: 9999,
        imgPath: redtape,
        cat: "shoes"
    },
    {
        id: 46,
        name: "Fila",
        des: "Leather Sneakers",
        price: 2999,
        imgPath: sneakers,
        cat: "shoes"
    },
    {
        id: 47,
        name: "Addidas",
        des: "Top Ten RB Sneakers",
        price: 8999,
        imgPath: tarzan,
        cat: "shoes"
    },
    {
        id: 48,
        name: "Puma Motorsport",
        des: "AMG Maco SL Sneakers",
        price: 9349,
        imgPath: textured,
        cat: "shoes"
    }

]

export default dbProducts;













































